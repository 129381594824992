// live server url
// export const BASE_URL = "http://192.168.0.58/FlixValetApi";
// export const BASE_IMG_URL = "http://192.168.0.58/api";
export const BASE_URL = "https://flixvalet.com/api";
export const BASE_IMAGE_URL = "https://flixvalet.com/api";

//export const MapKey = "AIzaSyBa7QJtTI2MWLkF9R1xR1__LrolePBOeuI";
//export const googleApiKey = "AIzaSyBa7QJtTI2MWLkF9R1xR1__LrolePBOeuI";
export const MapKey = "AIzaSyCn7ExL5cujUfiEysu9M6NaPK6fgOMCNtQ";
export const googleApiKey = "AIzaSyCn7ExL5cujUfiEysu9M6NaPK6fgOMCNtQ";

// export const MapKey = "AIzaSyD8LQxh0Ob4-9AoX9o6CHcUtW_UID0Rz_k";
// export const googleApiKey = "AIzaSyD8LQxh0Ob4-9AoX9o6CHcUtW_UID0Rz_k";

export const googleAddressDetailApi =
  "https://maps.googleapis.com/maps/api/place/details/json?";

export const SUB_DIRCTORY = "";
export const MapZoomDefault = 11;
export const MinZoom = 0;
export const MaxZoom = 30;
export const pageSize = 10;
export const googleLocationSearchContries = ["us", "ca", "in"];

export const imageMaxSize = "4000000";
//amezon s3 credencial
export const AccessKey = "AKIAQY4LJQSB4MN2ZRFO";
export const SecretKey = "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";
export const ImageBucketName = "flix-image";
export const region = "us-east-2";
export const dir = "CustomerProfiles";
// API Methods Name
export const login = "Account/LoginBrandAdmin";
export const loginUrl = "/account/Customerlogin";
export const addToCart = `Cart/AddToCart`;
//Mark Flagged customer
export const getAllConsumerQueue = `consumerqueue/GetAllConsumerQueue`;
//Book Mark Products
export const addBookMark = "consumer/AddConsumerProductBookMark";
export const getBookMark = "consumer/GetConsumerProductBookmarked";
export const removeBookMark = "consumer/RemoveBookmark";
export const doneNClearBookMarks = "consumer/UpdateBookmarks";
export const registerUrl = "/account/Register";
export const verifyEmail = "/account/verify-email";
export const searchParkingLocations = "/CustomerServices/SearchParkingLocations";
export const getBookingDetailsByLocation = "/CustomerServices/GetEstimatedBookingDetailsByLoc";
export const getMonthlyQRBookingInfo = "/CustomerServices/GetMonthlyQRBookingInfo";
export const getCountries = "/region/GetAllCountries";
export const getStates = "/region/GetStatesForCountry";
export const getColors = "/Master/GetColorMaster";
export const getManufacturers = "/Master/GetManufacturerMaster";
export const getVehicleTypes = "/Master/GetVehicleTypes";
export const addCustomerAddress = "/CustomerServices/AddCustomerAddress";
export const addCustomerVehicle = "/CustomerServices/AddCustomerVehicle";
export const bookParkingLocationv1 = "/master/BookParkingLocationv1";
// export const bookParkingLocationv1 = "/master/BookParkingLocationv1";
export const forgotPassword = "/account/forgot-password";
export const resetPassword = "/account/reset-password";
export const getCustomerBookingList = "/CustomerServices/GetCustomerBookingList";
export const getCustomerVehicle = "/CustomerServices/GetCustomerVehicles";
export const getCustomerAddress = "/CustomerServices/GetCustomerAddressById";
export const uploadsProfile = "/Attachment/UploadImage";
export const getCustomerVehicleById = "/CustomerServices/GetVehicleInfoById"
export const updateProfilePic = "/master/UpdateProfilePic";
export const updateUserInfo = "/CustomerServices/EditCustomerInfo";
export const deleteVihicle = "/CustomerServices/DeleteCustomerVehicle";
export const getCustomerInfoById = "/CustomerServices/GetCustomerInfo";
export const RequestToVehicle = "/CustomerServices/RequestVehicle";
export const GetNotificationByCustomerId = "/CustomerServices/GetNotificationByCustomerId";
export const changePassword = "/account/change-password";
export const GuestBooking = "/CustomerServices/GetElectronicPaymentDetails";
export const MakeElectronicPayment = "/CustomerServices/MakeElectronicPayment";
export const GetParkedVehicleListByCustomerId = "/CustomerServices/GetParkedVehicleListByCustomerId";
export const CancelBooking = "/CustomerServices/CancelBooking";
export const GetConversationList = "/Master/GetConversationList";
export const StaffCustomerConversation = "/Master/StaffCustomerConversation";
export const BrowserLaunch = "/CustomerServices/BrowserLaunch";
export const GetVehicleMasterData = "/master/GetVehicleMasterData";
export const GetPreBookingDetails = "CustomerServices/GetPreBookingDetails";
export const GetExtendBookingDetails = "/CustomerServices/GetExtendBookingDetails";
export const GetClientToken = "/CustomerServices/GetClientToken";

export const GetAvailableParkingDetailsv1 = "/ValetServices/GetAvailableParkingDetailsv1";
export const FetchGuestfromVehiclev1 = "/valetservices/FetchGuestfromVehiclev1";
export const GetBookingIdByVehicleNumber = "/valetservices/GetBookingIdByVehicleNumber";
export const CustomerEnterToLocation = "/ValetServices/CustomerEnterToLocation";
export const CustomerExitFromLocation = "/ValetServices/CustomerExitFromLocation";
export const GetBookingDetails = "/Master/GetBookingDetails";
export const MakeAdditionalPaymentFromQRScan = "/CustomerServices/MakeAdditionalPaymentFromQRScan";
export const FetchCustomer_URL = '/ValetServices/FetchCustomerFromEmailAndMobile'